import React from 'react';
import "./Roadmap.css";

const Roadmap = () => {
  return (
    <div className='roadmap-container'>
      <h3>Our Development Roadmap</h3>
      <div className='roadmap'>
        <div className='roadmap-phase'>
          <h4>Phase 1: Concept & Design</h4>
          <p>Conceptualization, game design, and setting up the foundational infrastructure for the GameFi platform.</p>
        </div>
        <div className='roadmap-phase'>
          <h4>Phase 2: Alpha Testing</h4>
          <p>Initial game mechanics and features will be tested in the alpha version, involving a small group of community members.</p>
        </div>
        <div className='roadmap-phase'>
          <h4>Phase 3: Public Beta Launch</h4>
          <p>We will release a public beta version with key features for a wider audience, gathering feedback and making improvements.</p>
        </div>
        <div className='roadmap-phase'>
          <h4>Phase 4: Official Launch</h4>
          <p>Our GameFi platform will officially go live with complete features, ongoing community support, and future updates.</p>
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
