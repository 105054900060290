import React from 'react'
import "./Direction.css"
import {FaArrowRight} from "react-icons/fa"
import element1Image from "../../assets/images/element1.png"
import element2Image from "../../assets/images/element2.png"
import element3Image from "../../assets/images/element3.png"


const Direction = () => {
  return (
    <div className='direction-container'>
        <div className='direction-text'>
            <h3>Our directions and advantages</h3>
            <p>Our GameFi DApp harnesses the power of blockchain technology to bring you a revolutionary <br/> platform that combines gaming with decentralized finance (DeFi). Here's what you can expect</p>
        </div>
        <div className='direction-elements'>
            <div className='direction-card'>
                <img src={element1Image} alt="Powerful for developers"/>
                <h5>Epic Gameplay Experience</h5>
                <a>
                    <p> Dive into a world where every action<br/>  
                    counts. Our game is designed to provide<br/>  
                    an exhilarating experience with high-stakes <br/> 
                    adventures, strategic battles, and a rich <br/> 
                    storyline.</p>
                </a>
                {/* <a href="##">Learn more <FaArrowRight/></a> */}
            </div>
            <div className='direction-card'>
                <img src={element2Image} alt="Powerful for developers"/>
                <h5>True Ownership of Assets</h5>
                <a>
                    <p>you have full ownership of your in-game <br/>
                    assets. Leveraging the power of blockchain,<br/>
                    every item, character, and achievement is securely <br/>
                    recorded on the blockchain</p>
                </a>
                {/* <a href="##">Learn more <FaArrowRight/></a> */}
            </div>
            <div className='direction-card'>
                <img src={element3Image} alt="Powerful for developers"/>
                <h5>Play-to-Earn Mechanics</h5>
                <a>
                    <p>We integrates innovative play-to-earn mechanics, <br/>
                    allowing you to earn cryptocurrency as you progress<br/>
                        through the game. Complete challenges, participate <br/>
                        in battles, and earn rewards </p>
                </a>
                {/* <a href="##">Learn more <FaArrowRight/></a> */}
            </div>
        </div>
    </div>
  )
}

export default Direction