import React from 'react'
import "./About.css"
import bitcoinLogo from "../../assets/images/bitcoin-logo.png"
import { ConnectButton } from '@rainbow-me/rainbowkit';
const About = () => {
  return (
    <div>
        <div className='about-container'>
            <div className='about-text'>
                <h3>Our goal and mission</h3>
                <p>revolutionize the gaming industry by seamlessly integrating blockchain 
                    <br/>
                    collaborate, innovate, and shape the future of gaming together
                </p>
            </div>
            <div className='about-details'>
                <div className='about-image'>
                    <img src={bitcoinLogo} alt="About"/>
                </div>
                <div className='about-info'>
                    <div className='about-paletee'>
                        <p>Empowerment Through Ownership: We believe that players <br/>should have complete control over their in-game assets.<br/>
                         By leveraging blockchain technology, we ensure that every <br/>item, character, and achievement is securely owned by <br/>
                         the player, enabling true asset ownership and the freedom <br/>to trade or sell without restrictions.</p>
                    </div>
                    <div className='about-paletee'>
                        <p>Rewarding Gameplay: Our mission is to make gaming not only <br/>fun but also financially rewarding. Through play-to-earn <br/>
                        mechanics, we provide players with opportunities to earn cryptocurrency <br/>and real-world value as they progress through the game.<br/>
                         Every battle, quest, and achievement has the potential to yield<br/> tangible rewards.</p>
                    </div>
                    <div className='about-paletee'>
                        <p>Security and Transparency: We are committed to creating a <br/>secure and transparent gaming environment. By utilizing smart <br/>
                        contracts and decentralized protocols, we ensure that all transactions <br/>and interactions are tamper-proof and fully transparent, <br/>
                        building trust and confidence among our players.</p>
                    </div>
                    <button><ConnectButton /></button>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default About