import React from 'react'
import { FaInstagram, FaTwitter, FaTelegram, FaFacebook,FaMapMarkerAlt, FaPhoneAlt, FaEnvelope,FaCompress  } from "react-icons/fa"
import "./Footer.css"

const Footer = () => {
  return (
    <div className='footer-container'>
        <div className='social-text'>
            <div className='social-icons'>
                    <a href="##"><FaInstagram/></a>
                    <a href='##'><FaTwitter/></a>
                    <a href="##"><FaTelegram/></a>
                    <a href="##"><FaFacebook/></a>
            </div>
            <div className='footer-text'>
                <h3>Join our community</h3>
            </div>
        </div>
        <div className='footer-details'>
            <div className='footer-quote-location'>
                <h3>“The one who owns the information — <br/>owns the world!”</h3>

                <a href="##"><FaCompress /><span> JHL INVESTMENT PTE. LTD. </span></a>
                {/* <a href="##"><FaMapMarkerAlt/> <span>PM C 13/F HARVARD COMM BLDG 105-111 THOMSON RD WAN CHAI HK</span></a>
                <a href="##"><FaPhoneAlt/> <span>+852-3069 7888  </span></a> */}
                <a href='##'><FaEnvelope/> <span>jhlinvestment@gmail.com</span></a>
            </div>
            <div className='footer-form'>
                <form>
                <div className='input-name'>
                    <input type="text" name="" id="" placeholder='First Name'/>
                    <input type="text" name="" id="" placeholder='Last Name'/>
                </div>
                <input type="email" name="" id="" placeholder='Email' className='input-email'/>
                <button type='submit'>Submit</button>
                </form>
            </div>
        </div>
        <p className='footer-copyright'>JHL INVESTMENT PTE. LTD.  © Copyright {new Date().getFullYear()}. All rights reserved</p>
    </div>
  )
}

export default Footer