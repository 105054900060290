import React, { useState } from 'react';
import "./FAQ.css";

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const questions = [
    { q: "What is GameFi?", a: "GameFi is a combination of gaming and decentralized finance (DeFi) that allows players to earn cryptocurrency rewards." },
    { q: "How does blockchain enhance gaming?", a: "Blockchain enables true ownership of in-game assets, secure trading, and transparent play-to-earn mechanics." },
    { q: "What cryptocurrencies are supported?", a: "Our platform supports major cryptocurrencies like ETH, BNB, and more." },
  ];

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className='faq-container'>
      <h3>Frequently Asked Questions</h3>
      <div className='faq-items'>
        {questions.map((item, index) => (
          <div key={index} className='faq-item'>
            <h4 onClick={() => toggleAnswer(index)}>{item.q}</h4>
            {openIndex === index && <p>{item.a}</p>}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
