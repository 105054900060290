import React, {useState} from 'react'
import './Heropage.css'
import {Drawer} from 'antd'
import {FaBars, FaTimes} from "react-icons/fa"
import logo from "../../assets/images/logo.png"
import line from "../../assets/images/Line.png"
import { ConnectButton } from '@rainbow-me/rainbowkit';

const Heropage = () => {
    const [open, setOpen] = useState(false)
    const [size, setSize] = useState();
    const showSideBar = () => {
        setSize('200px')
        setOpen(true)
    }
    const onClose = () =>{
        setOpen(false)
    }
  return (
    <div className='hero-container'>
        <div className='logo-menu'>
        <div className='logo'>
            <img src={logo} alt="Company logo" />
        </div>
        <div className='menu-list' >
            <ul>
                <li>Quests & Missions</li>
                <li>Teams</li>
                <li>How to start</li>
                <button>
                    <div>
                        <ConnectButton />
                    </div>
                </button>
            </ul>
        </div>
        <FaBars onClick={showSideBar} className='menu-bar'/>
        <Drawer placement='right' open={open} className='menu-drawer' size={size} onClose={onClose}>
        <FaTimes onClick={onClose} className='cancelIcon'/>
            <ul>
                <li>Quests & Missions</li>
                <li>Teams</li>
                <li>How to start</li>
                <button>
                    <div>
                        <ConnectButton />
                    </div>
                </button>
            </ul>
        </Drawer>
        </div>
        <div className='line'>
        <img src={line} alt="" />
        </div>
        <div>
            <h2 className='hero-text'>FORGED FOR TRIUMPH, <br/>DESTINED FOR VICTORY</h2>
            <p>
            Welcome to the next generation of gaming, where the virtual and real worlds collide to create an immersive and rewarding experience.
            </p>
        </div>
        <div>
            <form className='hero-form'>
            <input type="email" placeholder="Your Email Address" className='hero-input'/>
            <button className='hero-button'><ConnectButton/></button>
            </form>
            
        </div>
    </div>
  )
}

export default Heropage