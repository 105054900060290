import React from 'react';
import "./Tokenomics.css";

const Tokenomics = () => {
  return (
    <div className='tokenomics-container'>
      <h3>Tokenomics</h3>
      <p>Our tokenomics model ensures sustainability, growth, and value generation for all stakeholders in our GameFi platform.</p>
      <div className='tokenomics-details'>
        <div className='tokenomics-item'>
          <h4>Distribution</h4>
          <p>50% - Community & Rewards</p>
          <p>20% - Team & Advisors</p>
          <p>15% - Development Fund</p>
          <p>15% - Marketing & Partnerships</p>
        </div>
        <div className='tokenomics-item'>
          <h4>Usage</h4>
          <p>In-game purchases</p>
          <p>Staking & Yield Farming</p>
          <p>Governance & Voting</p>
          <p>Exclusive Content Access</p>
        </div>
      </div>
    </div>
  );
}

export default Tokenomics;
